<template>
  <div class="d-flex flex-column flex-root">

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <div class="jumbotron jumbotron-fluid d-flex flex-column">
          <!-- begin:: Header Mobile -->
          <KTHeaderMobileHome></KTHeaderMobileHome>
          <!-- end:: Header Mobile -->
          <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
          <KTHeader></KTHeader>
          <!-- begin:: Header -->

          <div class="row-carousel">
            <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="0"
                controls
                indicators
                background="#ababab"
                img-width="1024"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
            >
              <b-carousel-slide img-src="/media/bg/bghome2.jpg">
              <div class="d-none d-sm-block slide-home-1" style="background: rgba(0,0,0,0.5);border-radius: 10px;">
                <h1 style="color: white; font-size: 30px; font-weight: 400; text-shadow: 1px 1px #9da4b0;">
                  Melihat <b>Indonesia</b> Melalui Data <b>Hak Asasi Manusia</b>
                </h1>
              </div>
              <div class="landing-bottom">
                <router-link :to="{ name: 'dashboard' }" class="btn btn-secondary mt-auto tombol btn-landing">
                  Lihat Selengkapnya
                </router-link>
              </div>
              </b-carousel-slide>
              <b-carousel-slide img-src="/media/bg/bghome3.jpg">
                <div class="d-none d-sm-block slide-home-1" style="background: rgba(0,0,0,0.5);border-radius: 10px;">
                  <h1 style="color: white; font-size: 30px; font-weight: 400; text-shadow: 1px 1px #9da4b0;">
                    Melihat <b>Indonesia</b> Melalui Data <b>Hak Asasi Manusia</b>
                  </h1>
                </div>
                <br>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>

        <div class="row justify-content-center workingspace1"
             data-aos="zoom-in" data-aos-duration="1500"
             data-aos-offset="400" data-aos-easing="linear">
            <div class="col-md-3 col-12" style="text-align: right;"
                 data-aos="fade-right" data-aos-duration="1500" data-aos-delay="800">
              <h1 class="base-color-text">Tentang  portal layanan data aduan HAM</h1>
              <router-link class="btn btn-secondary tombol base-color-btn"
                           :to="{ name: 'general-definition' }"
              >
                Lihat Selengkapnya
              </router-link>
            </div>
            <div class="col-md-5 col-12" data-aos="fade-left" data-aos-duration="1500" data-aos-delay="800">
              <div class="card shadow p-3 mb-5 bg-white rounded">
                <div class="card-body">
                  <p class="card-text">
                    Portal Layanan Data Aduan HAM Komnas HAM RI Adalah Aplikasi yang menampilkan data statistik, dan
                    klasifikasi hak asasi manusia, bersumber dari data yang disampaikan masyarakat sebagai pengadu dan
                    diterima oleh Komisi Nasional Hak Asasi Manusia Republik Indonesia (Komnas HAM RI) melalui kantor pusat
                    yang berada di ibukota negara. Masyarakat dapat mengajukan permohonan data yang lebih spesifik
                    dengan mendaftarkan diri sebagai permohonan data melalui Aplikasi ini.
                  </p>
                </div>
              </div>
            </div>
        </div>

        <div class="row justify-content-center workingspace2" data-aos="zoom-in" data-aos-duration="1500" data-aos-offset="400">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-12 text-left">
                <h1 class="base-color-text">Situasi HAM Indonesia</h1>
              </div>
              <div class="col-md-4 col-12" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="800">
                <div class="list-group bg-transparent font-size-lg">
                  <router-link :to="{ name: 'dashboard' }" class="text-body">
                    <button type="button" class="list-group-item list-group-item-action
                      text-left bg-transparent"
                            :class="{'active-button' : this.imageHover==='data1'}"
                            @mouseover="changeImageData('/media/bg/situasiham1.jpg', 'data1')" >
                      <h2>Data HAM Aktual</h2>
                    </button>
                  </router-link>
                  <router-link :to="{ name: 'data-consultation' }" class="text-body">
                    <button type="button" class="list-group-item list-group-item-action
                  text-left bg-transparent"
                            :class="{'active-button' : this.imageHover==='data2'}"
                            @mouseover="changeImageData('/media/bg/situasiham2.jpg', 'data2')">
                      <h2>Data Konsultasi Aduan</h2>
                    </button>
                  </router-link>
                  <router-link :to="{ name: 'complaint-receipt-data' }" class="text-body">
                    <button type="button" class="list-group-item list-group-item-action
                  text-left bg-transparent"
                            :class="{'active-button' : this.imageHover==='data3'}"
                            @mouseover="changeImageData('/media/bg/situasiham3.jpg', 'data3')">
                      <h2>Tata cara dan Alur Permohonan Data</h2>
                    </button>
                  </router-link>
                  <router-link :to="{ name: 'complaint-data-application' }" class="text-body">
                    <button type="button" class="list-group-item list-group-item-action
                  text-left bg-transparent"
                            :class="{'active-button' : this.imageHover==='data4'}"
                            @mouseover="changeImageData('/media/bg/situasiham4.jpg', 'data4')">
                      <h2>Permohonan Data Aduan</h2>
                    </button>
                  </router-link>
                </div>
              </div>
              <div id="ImageDataView" class="col-md-8 col-12"
                   data-aos="flip-right" data-aos-duration="1500" data-aos-delay="800">
                <img :src="createDownloadUrl(this.imageData)"
                     class="rounded mx-auto d-block shadow p-3 mb-5 bg-white full-img">
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center workingspace3" data-aos="zoom-in" data-aos-duration="1500" data-aos-offset="500">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8 col-12">
                <h1 class="base-color-text">Publikasi Data Periodik</h1>
              </div>
            </div>
            <br>
            <div class="row justify-content-center">
              <div class="col-md-3 col-12">
                <div class="card">
                  <img src="/media/bg/Datagrafis.png" height="250px" width="200px"
                       class="card-img-top" data-aos="flip-left" data-aos-duration="1500" data-aos-delay="800">
                  <div class="card-body">
                    <h5 class="card-title">Data Grafis</h5>
                    <router-link class="btn btn-secondary tombol base-color-btn"
                                 :to="{ name: 'graph-data' }"
                    >
                      Lihat Selengkapnya
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="card">
                  <img src="/media/bg/Dataperiodik.jpeg" height="250px" width="200px"
                       class="card-img-top" data-aos="flip-right" data-aos-duration="1500" data-aos-delay="800">
                  <div class="card-body">
                    <h5 class="card-title">Laporan Data Periodik</h5>
                    <router-link class="btn btn-secondary tombol base-color-btn"
                                 :to="{ name: 'actual-data' }"
                    >
                      Lihat Selengkapnya
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <KTSubheaderHome
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
        />
        <KTAside v-if="asideEnabled"></KTAside>

        <KTFooter v-if="footerLayout('compact')"></KTFooter>
        <FooterExtended v-if="footerLayout('extended')"></FooterExtended>
      </div>
    </div>
    <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
    <KTScrollTop></KTScrollTop>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import FooterExtended from "@/view/layout/footer/FooterExtended.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import KTSubheaderHome from "@/view/layout/subheader/SubheaderHome";
import KTHeaderMobileHome from "@/view/layout/header/HeaderMobile";
import AOS from 'aos';
import 'aos/dist/aos.css'

export default {
  name: "Layout",
  components: {
    KTHeaderMobileHome,
    KTSubheaderHome,
    KTAside,
    KTHeader,
    KTFooter,
    FooterExtended,
    KTStickyToolbar,
    KTScrollTop,
    Loader
  },
  data() {
    return {
      imageData : "/media/bg/situasiham1.jpg",
      imageHover : "data1",
      slide: 0,
      sliding: null
    }
  },
  beforeCreate() {},
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    document.body.style.backgroundImage = "none";
    AOS.init();
    // document.body.style.backgroundColor = 'rgb(52,45,113)';
  },
  methods: {
    onPushData(data){
      this.$router.push(data);
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    changeImageData(pathImage, data){
      this.imageData = pathImage;
      this.imageHover = data;
    },
    getImageData(){
      return this.layoutConfig("self.body.background-home");
    },
    footerLayout(type) {
      return this.layoutConfig("footer.layout") === type;
    },
    createDownloadUrl(fileName) {
      return (""+fileName);
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
<style scoped>
  .jumbotron {
    padding-top: 0px;
    height:700px;
    width: 100%;
    position: relative;
  }

  .jumbotron .row-carousel{
    position: absolute;
    width: 100%;
  }

  .jumbotron .row-carousel .slide-home-1 {
    text-align: left;
    margin-bottom: 25%;
    padding: 20px;
    width: max-content;
  }

  .tombol {
    border-radius: 40px;
  }

  .workingspace1 {
    height:640px;
    width: 100%;
    background: url("/media/bg/home2.svg");
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    text-align: center;
    padding-top: 200px;
  }

  .workingspace1 h1{
    font-size: 40px;
    font-weight: bold;
  }

  .workingspace2 {
    height:640px;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    background: url("/media/bg/home3.svg");
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    text-align: center;
    padding-top: 50px;
  }
  .full-img {
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  .workingspace2 h1{
    font-size: 40px;
    font-weight: bold;
  }

  .workingspace3 {
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    text-align: center;
    padding-top: 150px;
  }
  .workingspace3 h1{
    font-size: 40px;
    font-weight: bold;
  }

  .base-color-text{
    color: rgb(52,45,113);
  }

  .base-color-btn{
    background-color: rgb(52,45,113);
    color: white;
  }

  .active-button{
    border-left: 6px solid #f44336;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 50px;
  }

  .active-button h1{
    font-weight: bold;
  }

  @media (min-width: 1320px) {
    .jumbotron {
      height:100vh;
    }
    .row-carousel{
      height: 100vh;
    }
    .workingspace1 {
      margin-top: 50px;
      height: 80vh;
    }
    .workingspace2 {
      height: 80vh;
    }
  }

  @media (max-width: 991px) {
    .jumbotron {
      padding-top: 0px;
      height:500px;
    }
    .jumbotron .row-carousel .slide-home-1 {
      margin-bottom: 15%;
      width: 100%;
    }
    .workingspace1 {
      padding-top: 100px;
    }
    .workingspace1 h1{
      font-size: 15px;
    }

    .workingspace2 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media (max-width: 720px) {
    .jumbotron {
      height: 350px;
    }
    .jumbotron .row-carousel .slide-home-1 {
      margin-bottom: 15%;
    }
  }
  @media (max-width: 600px) {
    .jumbotron {
      height: 300px;
    }
    .jumbotron .row-carousel .slide-home-1 {
      margin-bottom: 10%;
    }
  }
  @media (max-width: 400px) {
    .jumbotron {
      height: 200px;
    }
    .jumbotron .row-carousel .slide-home-1 {
      margin-bottom: 5%;
    }
  }
  /*@media (max-width: 550px) {*/
  /*  .jumbotron {*/
  /*    height: 300px;*/
  /*  }*/
  /*  .row-carousel{*/
  /*    height: 300px;*/
  /*  }*/
  /*}*/
</style>
